import React from "react"
import styled from "styled-components"
import SEO from "./SEO"

import "@blueprintjs/core/lib/css/blueprint.css"
import "rc-slider/assets/index.css"

const FullScreenMain = styled.main`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  overflow: hidden;
`

export default ({ children, title }) => {
  return (
    <>
      <SEO title={title} />
      <FullScreenMain>{children}</FullScreenMain>
    </>
  )
}
